<template>
  <section class="content">
    <table class="table table-hover" ref="tblcabang">
      <thead>
        <tr>
          <th>NAMA CABANG</th>
          <th>GRUP AKSES</th>
          <th>JUMLAH USER</th>
        </tr>
      </thead>
      <tbody @click="handleClick"></tbody>
    </table>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form role="form" @submit="submitForm">
            <div class="modal-header">
              <h5 class="modal-title">{{ formTitle }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <!-- <p v-if="errors.length" class="alert alert-danger">
                <b>Please correct the following error(s):</b>
              </p> -->
              <div class="row">
                <div class="col-md-6 form-group">
                  <label class="control-label">CABANG</label>
                  <select
                    v-model="form.branch_id"
                    id="branch_id"
                    name="branch_id"
                    class="form-control"
                  >
                    <option
                      v-for="cabang in groups"
                      v-bind:key="cabang.branch_id"
                      v-bind:value="cabang.branch_id"
                    >
                      {{ cabang.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">STAFF</label>
                  <v-select :options="optStaff" :reduce="opt => opt.staff_id" v-model="form.staff_pic" multiple></v-select>
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">NOTE</label>
                  <input
                    id="note"
                    class="form-control"
                    v-model="form.note"
                    type="text"
                    name="note"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">
                Save changes
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import $ from "jquery";

export default {
  name: "",
  data() {
    return {
      errors: [],
      groups: [],
      optStaff: [],
      method: "",
      roles: "",
      formTitle: "Tambah",
      form: {
        staff_pic: [],
        branch_id: "",
        note: "",
      },
    };
  },
  components: {
    vSelect,
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);

    // this.loadStaff();

    //get cabang
    authFetch("/akademik/data_induk_ruangan/groups")
      .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
      })
      .then((js) => {
        this.groups = js.data;
      });
  },
  methods: {
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }
    },
    submitForm: function (ev) {
      const e = this.$refs;

      // var data = Object.keys(this.form)
      //   .map(
      //     (key) =>
      //       encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
      //   )
      //   .join("&");
      var allData = { group: this.form };

      var urlSubmit = "/management/akses_cabang";
      if (this.method == "PUT")
        urlSubmit = "/management/akses_cabang/" + this.form.branch_id;

      authFetch(urlSubmit, {
        method: this.method,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        body: JSON.stringify(allData),
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          if (!js.success) {
            console.log(js.details);

            for (var key in js.details) {
              if (js.details.hasOwnProperty(key)) {
                this.errors.push(js.details[key]);
              }
            }

            return;
          }
          this.table.api().ajax.reload();
          $(e.formDialog).modal("hide");
        });

      ev.preventDefault();
    },
  },
  mounted() {
    const e = this.$refs;
    let self = this;

    authFetch('/management/akses_cabang/staff')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optStaff = js.data;
            })

    this.table = createTable(e.tblcabang, {
      title: "",
      roles: this.$route.params.roles,
      ajax: "/management/akses_cabang",
      columns: [
        { data: "cabang",
        render: function (data, type, row, meta) {
            return data;
        }, },
        { data: "name",
        render: function (data, type, row, meta) {
            // let grup = data.split(',');
            let act = ``;
            for(var x in data)
            {
              var staff = data[x];
              act += `
                <h5 style="display:inline-block;"><span class="badge badge-primary">${staff}</span></h5>
              `;
            }
              return act;
        } },
        { data: "jumlah", width: "50px",
        render: function (data, type, row, meta) {
            return "<h6 class='text-center'>" + data + "</h6>";
        } },
      ],
      filterBy: [0, 1],
      rowCallback: function (row, data) {},
      buttonClick: (evt) => {
        if (evt.role == "create") {
          self.form = {};
          self.method = "POST";
          self.errors = [];
          self.formTitle = "Tambah";
          $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
          self.form.branch_id = evt.data.branch_id;
          self.form.note = evt.data.note;

          let data = evt.data.name;
          let stafid = evt.data.staff_pic;
          let len = data.length;
          let len2 = stafid.length;

            self.form.staff_pic = [];
            for(let i = 0; i < len && i < len2; i++)
            {
              self.form.staff_pic.push({
                    label: data[i],
                    staff_id: stafid[i]
              });
              console.log(data[i]);
              console.log(stafid[i]);
            }
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit";
          $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
          // self.form = evt.data;
          console.log(evt);
          Swal.fire({
            title: "Hapus data?",
            icon: "question",
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
          }).then((result) => {
            if (result.isDenied) {
              authFetch("/management/akses_cabang/" + evt.data.branch_id, {
                method: "DELETE",
                body: "id=" + evt.data.branch_id,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  this.table.api().ajax.reload();
                });
            }
          });
        }
      },
    });
  },
};
</script>
